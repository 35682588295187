// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// Collapse the sub-nav when clicking outside of it.
on('click', '.sub-nav-mktg.on', event => {
  const container = <HTMLElement>event.currentTarget
  const wrapper = container.querySelector<HTMLElement>('.sub-nav-mktg-wrapper')!

  if (!wrapper.contains(event.target as Node)) {
    container.classList.remove('on')
  }
})

// Collapse the scrollnav sub-nav when selecting a section.
on('click', '.sub-nav-mktg.scrollnav.on .sub-nav-mktg-link', event => {
  event.currentTarget.closest<HTMLElement>('.sub-nav-mktg')!.classList.remove('on')
})

// On small screen, expand the sub-nav when clicking the active link.
on('click', '.sub-nav-mktg:not(.on) .sub-nav-mktg-link', event => {
  if (window.innerWidth >= 1280) return
  event.preventDefault()
  event.currentTarget.closest<HTMLElement>('.sub-nav-mktg')!.classList.add('on')
})
